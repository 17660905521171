@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
body {
  // background-color: #f6f8fa;
  background-color: #ffffff;
  height: 100vh;
}
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  margin-bottom: 32px;
  flex-grow: 2;
}
