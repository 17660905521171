/************************************
 **         APP WIDE MIXINS        **
 ************************************/
@import "./colors.scss";

// BREAKPOINTS MIXINS

$breakpoint-desktop: 1440px;
$breakpoint-desktop-small: 1280px;
$breakpoint-tablet: 1024px;
$breakpoint-mobile: 768px;
$breakpoint-mobile-small: 480px;

@mixin breakpoint-desktop {
  @media screen and (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin breakpoint-desktop-small {
  @media screen and (max-width: #{$breakpoint-desktop-small}) {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media screen and (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin breakpoint-mobile {
  @media screen and (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin breakpoint-mobile-small {
  @media screen and (max-width: #{$breakpoint-mobile-small}) {
    @content;
  }
}

@mixin breakpoint-custom($maxWidth) {
  @media screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin breakpoint-vertical-min($minHeight) {
  @media screen and (min-height: $minHeight) {
    @content;
  }
}

@mixin breakpoint-vertical-max($maxHeight) {
  @media screen and (max-height: $maxHeight) {
    @content;
  }
}
